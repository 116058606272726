import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MENUITEMS, Menu } from "./navbar-items";
import { Router, NavigationEnd } from "@angular/router";
import {
  SharedService,
  UserService,
  CartDataService,
} from "../../../../services";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrManager } from "ng6-toastr-notifications";
import { HttpErrorResponse } from "@angular/common/http";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { Subscription } from "rxjs";
declare var $: any;

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  show: boolean;
  public menuItems: Menu[];

  loginForm: FormGroup;
  submitted = false;
  loading = false;
  url: string;
  loggedIn: boolean;
  cartLength = 0;

  forgetPasswordForm: FormGroup;
  submittedForgot = false;
  closeResult: string;
  userDetails: any;
  tierOne = "/category/nondownloadable";
  tierTwo = "/category/nondownloadable";
  checkTheType: number | null;

  forgetPasswordFormTwo: FormGroup;
  submittedForgotPassRequest = false;
  selectedQuestion: string;
  currentUrl: string;
  checkUserType: string;

  modalReference: any;
  sharedLinkSubs: Subscription;
  cartItemSubs: Subscription;
  checkUserTypeSubs: Subscription;

  public isCollapsed = false;
  hideLoginPopup = true;
  toggleSignInPopup = true;
  toggleForgotPwdStepTwo = false;
  shoppingCartItems = [];
  showPassword = false;
  @ViewChild("myanchor", { static: false }) myAnchor: ElementRef<HTMLElement>;

  constructor(
    private shared: SharedService,
    private formbuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    public toastr: ToastrManager,
    private cartDataService: CartDataService,
    private modalService: NgbModal
  ) {
    this.shared.loggedInSouceObservable.subscribe((data: any) => {
      this.show = data;
      console.log("da", this.show);
    });
    this.sharedLinkSubs =
      this.shared.linkActiveSourceSourceObservable.subscribe((data: any) => {
        this.checkTheType = data;
      });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
      }
    });
    this.cartItemSubs = this.shared.cartItemSourceObservable.subscribe(
      (cartItem: number) => {
        this.cartLength = cartItem;
      }
    );

    this.checkUserTypeSubs = this.shared.userTypeSourceObservable.subscribe(
      (data: any) => {
        this.checkUserType = data;
      }
    );
  }

  ngOnInit() {
    this.shared.signInSourceSourceObservable.subscribe((data: any) => {
      if (data === true) {
        setTimeout(() => {
          let el: HTMLElement = this.myAnchor.nativeElement;
          el.click();
          // $('.ad').show();
        }, 500);
      }
    });
    this.menuItems = MENUITEMS.filter((menuItem) => menuItem);
    if (localStorage.getItem("token")) {
      this.show = true;
    } else {
      this.show = false;
    }

    this.loginForm = this.formbuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });

    this.forgetPasswordForm = this.formbuilder.group({
      user_identity: ["", [Validators.required]],
    });

    this.forgetPasswordFormTwo = this.formbuilder.group({
      secure_ans: ["", [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
          ),
        ],
      ],
    });
    // this.getCartDataItem();
  }

  ngOnDestroy() {
    this.sharedLinkSubs.unsubscribe();
    this.cartItemSubs.unsubscribe();
    this.checkUserTypeSubs.unsubscribe();
  }

  getCartDataItem() {
    this.cartDataService.getCartItem().subscribe((data: any) => {
      this.shoppingCartItems = data.success.cartProducts;
      this.shared.changeCartItemLength(this.shoppingCartItems.length);
    });
  }

  showSignInPopup() {
    $(".sign-in-popup").show();
  }

  closeMyAcc() {
    $(".sign-in-popup").hide();
  }

  hideSignInPopup() {
    this.submitted = false;
    this.loginForm.reset();
    $(".sign-in-popup").hide();
    this.toggleSignInPopup = true;
    this.toggleForgotPwdStepTwo = false;
    this.submittedForgot = false;
    this.forgetPasswordForm.reset();
    this.submittedForgotPassRequest = false;
    this.forgetPasswordFormTwo.reset();
  }

  get f() {
    return this.loginForm.controls;
  }

  loginFormData(event) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.loading = false;
          localStorage.setItem("currentUser", JSON.stringify(data.user));
          localStorage.setItem("token", JSON.stringify(data.user.token));
          localStorage.setItem("userType", JSON.stringify(data.user.user_type));
          window.location.href = "category";
          this.shared.changeLoginCondition(true);
          this.toastr.successToastr(data.message, "Success");
          this.loginForm.reset();
        },
        (error: HttpErrorResponse) => {
          this.toastr.errorToastr(error.error.error, "Oops!", {
            showCloseButton: true,
          });
          this.loading = false;
        }
      );
  }

  OpenForgotPassword(content) {
    this.hideSignInPopup();
    this.hideLoginPopup = false;
    this.modalReference = this.modalService.open(content, {
      backdrop: "static",
      keyboard: false,
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.hideLoginPopup = true;
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  get forgotPas() {
    return this.forgetPasswordForm.controls;
  }

  forgetPassword(contentrequest) {
    this.submittedForgot = true;
    if (this.forgetPasswordForm.invalid) {
      return;
    }
    this.loading = true;

    this.userService
      .fogetPasswordStepFirst(this.forgetPasswordForm.value)
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.forgetPasswordForm.reset();
          this.selectedQuestion = data.success.secure_ques;
          this.modalService.dismissAll();
          this.submittedForgot = false;
          this.toggleForgotPwdStepTwo = true;
          this.toggleSignInPopup = false;
          //  this.modalReference = this.modalService.open(contentrequest);
          this.modalReference.result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
        },
        (error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            if (!navigator.onLine) {
              this.toastr.errorToastr(
                "Connection Lost! You are not connected to internet.",
                "Oops!",
                {
                  position: "top-right",
                  showCloseButton: true,
                }
              );
              return;
            }
          }
          this.loading = false;
          this.toggleForgotPwdStepTwo = false;
          this.toastr.errorToastr(error.error.error, "Oops!", {
            position: "top-right",
            showCloseButton: true,
          });
        }
      );
  }

  get forgotPasswordRequest() {
    return this.forgetPasswordFormTwo.controls;
  }

  forgetPasswordStepTwo() {
    this.submittedForgotPassRequest = true;
    if (this.forgetPasswordFormTwo.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .fogetPasswordStepTwo(
        this.selectedQuestion,
        this.forgetPasswordFormTwo.value.secure_ans,
        this.forgetPasswordFormTwo.value.email
      )
      .subscribe(
        (data: any) => {
          this.loading = false;

          this.forgetPasswordFormTwo.reset();
          this.submittedForgotPassRequest = false;
          this.hideSignInPopup();
          localStorage.setItem(
            "currentUserDataStepTwo",
            JSON.stringify(data.success)
          );
          this.router.navigate(["/forgot-password-success"]);
          this.modalReference.close();
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.toastr.errorToastr(error.error.error, "Oops!", {
            showCloseButton: true,
          });
        }
      );
  }

  logout() {
    // if (this.shoppingCartItems && this.shoppingCartItems.length) {
    //   this.emptyCartItem();
    // }
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
    localStorage.clear();
    this.shared.changeLoginCondition(false);
    this.router.navigate(["/"]);
  }

  navigateToForgotPassword() {
    this.toggleSignInPopup = false;
  }

  // emptyCartItem() {
  //   const cartObj = {
  //     cart_id: '',
  //   };
  //   this.cartDataService.deleteCartItem(cartObj).subscribe(
  //     (data: any) => {
  //       this.getCartDataItem();
  //     },
  //     (error: HttpErrorResponse) => { }
  //   );
  // }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
